<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="periods" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagPeriods"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '800px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} {{ moduleData.metadata.description }} ⚡ Düngerezept-Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('periods')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel v-for="(element, index) in this.nodeDataPeriods" :key="index" :header="`${index+1}: ${this.getDiagDataPeriods(index, 'fertiName').value}`">  
                        <Panel :header="`${this.getDiagDataPeriods(index, 'fertiName').value} - EC/pH Sollwerte`">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Der EC-Sollwert sowie pH-Sollwert geben die jeweiligen Sollwerte für die Düngeregelung dieses Rezepts vor.`"></i>
                                <i class="fi fi-rr-bottle bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpointEc" ref="setpointEc" :min="0" :max="10"
                                                v-model="this.getDiagDataPeriods(index, 'setpointEc').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-5xl"  />
                                                <label for="setpointEc" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpointEc').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataPeriods(index, 'setpointEc').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpointPh" ref="setpointPh" :min="0" :max="10"
                                                v-model="this.getDiagDataPeriods(index, 'setpointPh').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-5xl"  />
                                                <label for="setpointPh" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpointPh').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataPeriods(index, 'setpointPh').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'fertiName').value} - Düngermischung / Verhältnis`" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Anteile legen fest, in welchem Verhältnis die Menüs gemischt werden. Ein Anteil A und 2 Anteil B z.B. entsprechen somit 1:2.`"></i>
                                <i class="fi fi-rr-chart-set-theory bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid">
                                <div class="field col-3">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center mb-2">
                                            <label for="setpointFertiA" class="mr-0">{{ this.getDiagDataPeriods(index, 'setpointFertiA').label }}</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <Dropdown v-model="this.getDiagDataPeriods(index, 'setpointFertiA').value" ref="setpointFertiA" :options="getFertiRatio" optionLabel="label" optionValue="value" placeholder="Mischung" class="w-full" />
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center mb-2">
                                            <label for="setpointFertiA" class="mr-0">{{ this.getDiagDataPeriods(index, 'setpointFertiB').label }}</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <Dropdown v-model="this.getDiagDataPeriods(index, 'setpointFertiB').value" ref="setpointFertiB" :options="getFertiRatio" optionLabel="label" optionValue="value" placeholder="Mischung" class="w-full" />
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center mb-2">
                                            <label for="setpointFertiA" class="mr-0">{{ this.getDiagDataPeriods(index, 'setpointFertiC').label }}</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <Dropdown v-model="this.getDiagDataPeriods(index, 'setpointFertiC').value" ref="setpointFertiC" :options="getFertiRatio" optionLabel="label" optionValue="value" placeholder="Mischung" class="w-full" />
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center mb-2">
                                            <label for="setpointFertiA" class="mr-0">{{ this.getDiagDataPeriods(index, 'setpointFertiD').label }}</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <Dropdown v-model="this.getDiagDataPeriods(index, 'setpointFertiD').value" ref="setpointFertiD" :options="getFertiRatio" optionLabel="label" optionValue="value" placeholder="Mischung" class="w-full" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'fertiName').value} - Säure / Lauge`" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Anteile bei Säure und Lauge erlauben eine Anpassung der Einspritzintervalle. Sie sind somit weniger als Verhältnis zu betrachten, viel mehr als Optimierung der Einspritz- und Pausezeiten der Ventile.`"></i>
                                <i class="fi fi-rr-chart-set-theory bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid">
                                <div class="field col-3">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center mb-2">
                                            <label for="setpointFertiA" class="mr-0">{{ this.getDiagDataPeriods(index, 'setpointFertiS').label }}</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <Dropdown v-model="this.getDiagDataPeriods(index, 'setpointFertiS').value" ref="setpointFertiS" :options="getFertiRatio" optionLabel="label" optionValue="value" placeholder="Mischung" class="w-full" />
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center mb-2">
                                            <label for="setpointFertiA" class="mr-0">{{ this.getDiagDataPeriods(index, 'setpointFertiL').label }}</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <Dropdown v-model="this.getDiagDataPeriods(index, 'setpointFertiL').value" ref="setpointFertiL" :options="getFertiRatio" optionLabel="label" optionValue="value" placeholder="Mischung" class="w-full" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'fertiName').value} - EC-Korrektur Strahlung`" class="mt-3" v-if="this.getDiagDataPeriods(index, 'fertiEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die EC-Korrektur (oder EC-Absenkung) erlaubt eine Korrektur des EC-Wertes in einer bestimmten Zeitspanne, sofern sich die Globalstrahlung zumindest über dem <b>EC-Korrektur ab</b> Wert befindet. Meist wird dann der EC-Wert abgesenkt, um zur Mittagszeit weniger Dünger zu verwenden.`"></i>
                                <i class="fi fi-rr-chat-arrow-down bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'setpointEcAdjustEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'setpointEcAdjustEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'setpointEcAdjustEnable').value">
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataPeriods(index, 'TimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">Periode Beginn</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeEnd').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeEnd').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd">Periode Ende</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'setpointEcAdjustEnable').value">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpointEcAdjustStart" ref="setpointEcAdjustStart" :min="0" :max="3000"
                                                v-model="this.getDiagDataPeriods(index, 'setpointEcAdjustStart').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="setpointEcAdjustStart" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpointEcAdjustStart').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'setpointEcAdjustStart').unit }}</span>
                                        </span>
                                    </div>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataPeriods(index, 'setpointEcAdjustStart').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpointEcAdjustEnd" ref="setpointEcAdjustEnd" :min="0" :max="3000"
                                                v-model="this.getDiagDataPeriods(index, 'setpointEcAdjustEnd').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="setpointEcAdjustEnd" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpointEcAdjustEnd').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'setpointEcAdjustEnd').unit }}</span>
                                        </span>
                                    </div>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataPeriods(index, 'setpointEcAdjustEnd').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpointEcAdjustValue" ref="setpointEcAdjustValue" :min="-10" :max="10"
                                                v-model="this.getDiagDataPeriods(index, 'setpointEcAdjustValue').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="setpointEcAdjustValue" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpointEcAdjustValue').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'setpointEcAdjustValue').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'fertiName').value} - Düngerzusätze`" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Düngerzusätze legen fest, ob und welcher Düngerzusatz beim Verwenden dieses Rezepts verwendet werden soll. Meist sind Dosierpumpen o.Ä. in Verwendung mit diesen Ausgängen. Sofern die Dosierpumpe mit einer analogen Ansteuerung ausgestattet ist, kann mit dem Anteil das Verhältnis zum Durchfluss festgelegt werden. <b>Bsp.:</b> 10 Liter Zusatz auf 100.000 Liter Wasser = 10/100000=<b><u>0,0001 %</u></b> Anteil.`"></i>
                                <i class="fi fi-rr-syringe bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col mr-2">
                                    <div class="card flex flex-wrap justify-content-center gap-3">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPeriods(index, 'fertiAddition1').value" inputId="fertiAddition1" :binary="true" />
                                            <label for="fertiAddition1" class="ml-2"> {{ this.getDiagDataPeriods(index, 'fertiAddition1').label }} </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPeriods(index, 'fertiAddition2').value" inputId="fertiAddition2" :binary="true" class="ml-3"/>
                                            <label for="fertiAddition2" class="ml-2"> {{ this.getDiagDataPeriods(index, 'fertiAddition2').label }} </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPeriods(index, 'fertiAddition3').value" inputId="fertiAddition3" :binary="true" class="ml-3"/>
                                            <label for="fertiAddition3" class="ml-2"> {{ this.getDiagDataPeriods(index, 'fertiAddition3').label }} </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPeriods(index, 'fertiAddition4').value" inputId="fertiAddition4" :binary="true" class="ml-3"/>
                                            <label for="fertiAddition4" class="ml-2"> {{ this.getDiagDataPeriods(index, 'fertiAddition4').label }} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="fertiAddition1Value" ref="fertiAddition1Value" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'fertiAddition1Value').value" autofocus @focus="focusElement"
                                                :minFractionDigits="5" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="fertiAddition1Value" class="mr-2">{{ this.getDiagDataPeriods(index, 'fertiAddition1Value').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'fertiAddition1Value').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="fertiAddition2Value" ref="fertiAddition2Value" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'fertiAddition2Value').value" autofocus @focus="focusElement"
                                                :minFractionDigits="5" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="fertiAddition2Value" class="mr-2">{{ this.getDiagDataPeriods(index, 'fertiAddition2Value').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'fertiAddition2Value').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="fertiAddition3Value" ref="fertiAddition3Value" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'fertiAddition3Value').value" autofocus @focus="focusElement"
                                                :minFractionDigits="5" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="fertiAddition3Value" class="mr-2">{{ this.getDiagDataPeriods(index, 'fertiAddition3Value').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'fertiAddition3Value').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="fertiAddition4Value" ref="fertiAddition4Value" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'fertiAddition4Value').value" autofocus @focus="focusElement"
                                                :minFractionDigits="5" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="fertiAddition4Value" class="mr-2">{{ this.getDiagDataPeriods(index, 'fertiAddition4Value').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'fertiAddition4Value').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'fertiName').value} - EC-Vorregelung`" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Sofern eine EC-Vorregelung verbaut ist, kann hier der EC-Wert angegeben werden, auf den mit diesem Rezept geregelt werden soll. Die Steuerung versucht dann z.B. Frischwasser und Rücklaufwasser zu vermischen um Dünger zu sparen. Je höher dieser Wert eingestellt wird, desto weniger Dünger wird von der Düngestation dosiert.`"></i>
                                <i class="fi fi-rr-bottle bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpointEcPremix" ref="setpointEcPremix" :min="0" :max="10"
                                                v-model="this.getDiagDataPeriods(index, 'setpointEcPremix').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="setpointEcPremix" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpointEcPremix').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'setpointEcPremix').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Eine Differenz mit Angabe <b>- (Minus)</b> gibt die Abweichnung nach unten an (z.B. bei EC: zu wenig Dünger). Die Differenz mit <b>+ (Plus)</b> hingegen, gibt die Abweichung nach oben an (z.B. bei pH: zu wenig Säure). Es werden nur positive Zahlen akzeptiert.`"></i>
                                <i class="fi fi-rr-bell-ring bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmDiffEcMinus" ref="alarmDiffEcMinus" :min="0" :max="100"
                                            v-model="this.getDiagDataPeriods(index, 'alarmDiffEcMinus').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataPeriods(index, 'alarmDiffEcMinus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmDiffEcMinus" class="mr-2">{{ this.getDiagDataPeriods(index, 'alarmDiffEcMinus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'alarmEcMinusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmDiffEcPlus" ref="alarmDiffEcPlus" :min="0" :max="100"
                                            v-model="this.getDiagDataPeriods(index, 'alarmDiffEcPlus').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataPeriods(index, 'alarmDiffEcPlus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmDiffEcPlus" class="mr-2">{{ this.getDiagDataPeriods(index, 'alarmDiffEcPlus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'alarmEcPlusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmDiffPhMinus" ref="alarmDiffPhMinus" :min="0" :max="100"
                                            v-model="this.getDiagDataPeriods(index, 'alarmDiffPhMinus').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataPeriods(index, 'alarmDiffPhMinus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmDiffPhMinus" class="mr-2">{{ this.getDiagDataPeriods(index, 'alarmDiffPhMinus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'alarmPhMinusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmDiffPhPlus" ref="alarmDiffPhPlus" :min="0" :max="100"
                                            v-model="this.getDiagDataPeriods(index, 'alarmDiffPhPlus').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataPeriods(index, 'alarmDiffPhPlus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmDiffPhPlus" class="mr-2">{{ this.getDiagDataPeriods(index, 'alarmDiffPhPlus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'alarmPhPlusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmverzögerungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Alarmverzögerungen geben an, wie lange bei einer zutreffenden Abweichung gewartet wird, bis ein Alarm aktiviert wird.`"></i>
                                <i class="fi fi-rr-time-half-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="alarmDiffEcDelay" ref="alarmDiffEcDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'alarmDiffEcDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="alarmFlowDiffDelay" class="mr-2">{{ this.getDiagDataPeriods(index, 'alarmDiffEcDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="alarmDiffPhDelay" ref="alarmDiffPhDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'alarmDiffPhDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="alarmDiffPhDelay" class="mr-2">{{ this.getDiagDataPeriods(index, 'alarmDiffPhDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col">
                                <div class="flex justify-content-center mt-2">
                                    <Button label="Düngerbezeichnung ändern" @click="toogleNameDiag(this.getDiagDataPeriods(index, 'fertiName'))" />
                                </div>
                            </div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagTogglePeriods" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('periods')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Verzögerungen">
                        <Panel header="Verzögerungen">
                            <template #icons>
                                <i class="fi fi-rr-time-half-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="preRunDelay" ref="preRunDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataCfg('preRunDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="preRunDelay" class="mr-2">{{ this.getDiagDataCfg('preRunDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="preEndDelay" ref="preEndDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataCfg('preEndDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="preEndDelay" class="mr-2">{{ this.getDiagDataCfg('preEndDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Hauptmessung" v-if="isMaster">
                        <Panel header="EC - Sensorskalierung">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecSensInMin" ref="ecSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('ecSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecSensOutMin" ref="ecSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('ecSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('ecSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecSensInMax" ref="ecSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('ecSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecSensOutMax" ref="ecSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('ecSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('ecSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="pH - Sensorskalierung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phSensInMin" ref="phSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('phSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phSensOutMin" ref="phSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('phSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('phSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phSensInMax" ref="phSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('phSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phSensOutMax" ref="phSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('phSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('phSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensor-Einstellungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecSensFilterValue" ref="ecSensFilterValue" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('ecSensFilterValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecSensFilterValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecSensFilterValue" class="mr-2">{{ getDiagDataCfg('ecSensFilterValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phSensFilterValue" ref="phSensFilterValue" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('phSensFilterValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phSensFilterValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phSensFilterValue" class="mr-2">{{ getDiagDataCfg('phSensFilterValue').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecSensOffset" ref="ecSensOffset" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('ecSensOffset').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecSensOffset').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecSensOffset" class="mr-2">{{ getDiagDataCfg('ecSensOffset').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phSensOffset" ref="phSensOffset" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('phSensOffset').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phSensOffset').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phSensOffset" class="mr-2">{{ getDiagDataCfg('phSensOffset').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Kontrollmessung" v-if="isMaster">
                        <Panel header="Kontrollmessung verwenden">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('checkSensEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('checkSensEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="EC - Sensorskalierung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecCheckSensInMin" ref="ecCheckSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('ecCheckSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecCheckSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecCheckSensOutMin" ref="ecCheckSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('ecCheckSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('ecCheckSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecCheckSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecCheckSensInMax" ref="ecCheckSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('ecCheckSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecCheckSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="ecCheckSensOutMax" ref="ecCheckSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('ecCheckSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('ecCheckSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="ecCheckSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="pH - Sensorskalierung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phCheckSensInMin" ref="phCheckSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('phCheckSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phCheckSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phCheckSensOutMin" ref="phCheckSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('phCheckSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('phCheckSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phCheckSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phCheckSensInMax" ref="phCheckSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('phCheckSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phCheckSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="phCheckSensOutMax" ref="phCheckSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('phCheckSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('phCheckSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="phCheckSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensor-Einstellungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecCheckSensFilterValue" ref="ecCheckSensFilterValue" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('ecCheckSensFilterValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecCheckSensFilterValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecCheckSensFilterValue" class="mr-2">{{ getDiagDataCfg('ecCheckSensFilterValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phCheckSensFilterValue" ref="phCheckSensFilterValue" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('phCheckSensFilterValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phCheckSensFilterValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phCheckSensFilterValue" class="mr-2">{{ getDiagDataCfg('phCheckSensFilterValue').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecCheckSensOffset" ref="ecCheckSensOffset" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('ecCheckSensOffset').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecCheckSensOffset').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecCheckSensOffset" class="mr-2">{{ getDiagDataCfg('ecCheckSensOffset').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phCheckSensOffset" ref="phCheckSensOffset" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('phCheckSensOffset').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phCheckSensOffset').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phCheckSensOffset" class="mr-2">{{ getDiagDataCfg('phCheckSensOffset').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="EC-Regler" v-if="isMaster">
                        <Panel header="EC-Regelung">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecPfactor" ref="ecPfactor" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('ecPfactor').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecPfactor').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecPfactor" class="mr-2">{{ getDiagDataCfg('ecPfactor').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecIfactor" ref="ecIfactor" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('ecIfactor').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecIfactor').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecIfactor" class="mr-2">{{ getDiagDataCfg('ecIfactor').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecNull" ref="ecNull" :min="0" :max="100"
                                            v-model="getDiagDataCfg('ecNull').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecNull').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecNull" class="mr-2">{{ getDiagDataCfg('ecNull').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecOverdrive" ref="ecOverdrive" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('ecOverdrive').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecOverdrive').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecOverdrive" class="mr-2">{{ getDiagDataCfg('ecOverdrive').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="ecCancel" ref="ecCancel" :min="0" :max="100"
                                            v-model="getDiagDataCfg('ecCancel').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecCancel').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecCancel" class="mr-2">{{ getDiagDataCfg('ecCancel').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="ecReleaseWindow" ref="ecReleaseWindow" :min="0" :max="100"
                                            v-model="getDiagDataCfg('ecReleaseWindow').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecReleaseWindow').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecReleaseWindow" class="mr-2">{{ getDiagDataCfg('ecReleaseWindow').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="ecGoodDelay" ref="ecGoodDelay" :min="0" :max="100000"
                                            v-model="getDiagDataCfg('ecGoodDelay').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecGoodDelay').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecGoodDelay" class="mr-2">{{ getDiagDataCfg('ecGoodDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Dosierintervalle" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecMinPower" ref="ecMinPower" :min="0" :max="100"
                                            v-model="getDiagDataCfg('ecMinPower').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecMinPower').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecMinPower" class="mr-2">{{ getDiagDataCfg('ecMinPower').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecMaxPower" ref="ecMaxPower" :min="0" :max="100"
                                            v-model="getDiagDataCfg('ecMaxPower').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecMaxPower').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecMaxPower" class="mr-2">{{ getDiagDataCfg('ecMaxPower').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecMinTime" ref="ecMinTime" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('ecMinTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecMinTime').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecMinTime" class="mr-2">{{ getDiagDataCfg('ecMinTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecMaxTime" ref="ecMaxTime" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('ecMaxTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecMaxTime').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecMaxTime" class="mr-2">{{ getDiagDataCfg('ecMaxTime').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecMinTimePause" ref="ecMinTimePause" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('ecMinTimePause').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecMinTimePause').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecMinTimePause" class="mr-2">{{ getDiagDataCfg('ecMinTimePause').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="ecMaxTimePause" ref="ecMaxTimePause" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('ecMaxTimePause').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('ecMaxTimePause').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="ecMaxTimePause" class="mr-2">{{ getDiagDataCfg('ecMaxTimePause').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="pH-Regler" v-if="isMaster">
                        <Panel header="pH-Regelung">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phPfactor" ref="phPfactor" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('phPfactor').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phPfactor').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phPfactor" class="mr-2">{{ getDiagDataCfg('phPfactor').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phIfactor" ref="phIfactor" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('phIfactor').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phIfactor').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phIfactor" class="mr-2">{{ getDiagDataCfg('phIfactor').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phNull" ref="phNull" :min="0" :max="100"
                                            v-model="getDiagDataCfg('phNull').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phNull').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phNull" class="mr-2">{{ getDiagDataCfg('phNull').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phOverdrive" ref="phOverdrive" :min="-100" :max="100"
                                            v-model="getDiagDataCfg('phOverdrive').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phOverdrive').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phOverdrive" class="mr-2">{{ getDiagDataCfg('phOverdrive').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="phCancel" ref="phCancel" :min="0" :max="100"
                                            v-model="getDiagDataCfg('phCancel').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phCancel').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phCancel" class="mr-2">{{ getDiagDataCfg('phCancel').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="phReleaseWindow" ref="phReleaseWindow" :min="0" :max="100"
                                            v-model="getDiagDataCfg('phReleaseWindow').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phReleaseWindow').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phReleaseWindow" class="mr-2">{{ getDiagDataCfg('phReleaseWindow').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="phGoodDelay" ref="phGoodDelay" :min="0" :max="100000"
                                            v-model="getDiagDataCfg('phGoodDelay').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phGoodDelay').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phGoodDelay" class="mr-2">{{ getDiagDataCfg('phGoodDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Dosierintervalle" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phMinPower" ref="phMinPower" :min="0" :max="100"
                                            v-model="getDiagDataCfg('phMinPower').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phMinPower').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phMinPower" class="mr-2">{{ getDiagDataCfg('phMinPower').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phMaxPower" ref="phMaxPower" :min="0" :max="100"
                                            v-model="getDiagDataCfg('phMaxPower').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phMaxPower').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phMaxPower" class="mr-2">{{ getDiagDataCfg('phMaxPower').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phMinTime" ref="phMinTime" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('phMinTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phMinTime').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phMinTime" class="mr-2">{{ getDiagDataCfg('phMinTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phMaxTime" ref="phMaxTime" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('phMaxTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phMaxTime').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phMaxTime" class="mr-2">{{ getDiagDataCfg('phMaxTime').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phMinTimePause" ref="phMinTimePause" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('phMinTimePause').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phMinTimePause').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phMinTimePause" class="mr-2">{{ getDiagDataCfg('phMinTimePause').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="phMaxTimePause" ref="phMaxTimePause" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('phMaxTimePause').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('phMaxTimePause').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="phMaxTimePause" class="mr-2">{{ getDiagDataCfg('phMaxTimePause').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Düngerzusätze" v-if="isMaster">
                        <Panel header="Dosierpumpen Bereichs-Skalierung">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition1ValueMin" ref="fertiAddition1ValueMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition1ValueMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition1ValueMin').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition1ValueMin" class="mr-2">{{ getDiagDataCfg('fertiAddition1ValueMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition1ValueMax" ref="fertiAddition1ValueMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition1ValueMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition1ValueMax').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition1ValueMax" class="mr-2">{{ getDiagDataCfg('fertiAddition1ValueMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition2ValueMin" ref="fertiAddition2ValueMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition2ValueMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition2ValueMin').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition2ValueMin" class="mr-2">{{ getDiagDataCfg('fertiAddition2ValueMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition2ValueMax" ref="fertiAddition2ValueMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition2ValueMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition2ValueMax').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition2ValueMax" class="mr-2">{{ getDiagDataCfg('fertiAddition2ValueMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition3ValueMin" ref="fertiAddition3ValueMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition3ValueMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition3ValueMin').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition3ValueMin" class="mr-2">{{ getDiagDataCfg('fertiAddition3ValueMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition3ValueMax" ref="fertiAddition3ValueMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition3ValueMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition3ValueMax').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition3ValueMax" class="mr-2">{{ getDiagDataCfg('fertiAddition3ValueMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition4ValueMin" ref="fertiAddition4ValueMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition4ValueMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition4ValueMin').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition4ValueMin" class="mr-2">{{ getDiagDataCfg('fertiAddition4ValueMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="fertiAddition4ValueMax" ref="fertiAddition4ValueMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('fertiAddition4ValueMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('fertiAddition4ValueMax').unit}`"
                                            :minFractionDigits="4" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="fertiAddition4ValueMax" class="mr-2">{{ getDiagDataCfg('fertiAddition4ValueMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMisc" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('misc')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagPhotons"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Fixe Zeitstarts
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="diagTogglePhotons" @keydown.enter="saveDiag('photons')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel v-for="(element, index) in this.nodeDataPhotons" :key="index" :header="`${index+1}: Zeit`">
                        <Panel header="Diesen Zeitstart verwenden?">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Der Hauptschalter gibt diese einzelne Zeit frei oder sperrt sie. Wenn kein Tag ausgewählt wurde ist es so, als wäre dieser Schalter ebenfalls ausgeschaltet.`"></i>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataPhotons(index, 'TimeEnable').value" /><span class="ml-2">{{ this.getDiagDataPhotons(index, 'TimeEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Wochentage für diesen Zeistart auswählen" class="mt-3" v-if="this.getDiagDataPhotons(index, 'TimeEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Die Wochentagen definieren jene Tage, an denen die Bewässerungsgruppe starten darf. In Verbindung mit der untenstehenden Startzeit wird somit der fixe Zeitstart festgelegt.`"></i>
                                <i class="fi fi-rr-calendar bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col mr-2">
                                    <div class="card flex flex-wrap justify-content-center gap-3">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPhotons(index, 'TimeSetDayMon').value" inputId="TimeSetDayMon" :binary="true" />
                                            <label for="TimeSetDayMon" class="ml-2"> MO </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPhotons(index, 'TimeSetDayTue').value" inputId="TimeSetDayTue" :binary="true" class="ml-3"/>
                                            <label for="TimeSetDayTue" class="ml-2"> DI </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPhotons(index, 'TimeSetDayWed').value" inputId="TimeSetDayWed" :binary="true" class="ml-3"/>
                                            <label for="TimeSetDayWed" class="ml-2"> MI </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPhotons(index, 'TimeSetDayThu').value" inputId="TimeSetDayThu" :binary="true" class="ml-3"/>
                                            <label for="TimeSetDayThu" class="ml-2"> DO </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPhotons(index, 'TimeSetDayFri').value" inputId="TimeSetDayFri" :binary="true" class="ml-3"/>
                                            <label for="TimeSetDayFri" class="ml-2"> FR </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPhotons(index, 'TimeSetDaySat').value" inputId="TimeSetDaySat" :binary="true" class="ml-3"/>
                                            <label for="TimeSetDaySat" class="ml-2"> SA </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPhotons(index, 'TimeSetDaySun').value" inputId="TimeSetDaySun" :binary="true" class="ml-3"/>
                                            <label for="TimeSetDaySun" class="ml-2"> SO </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Zeit für Gruppenstart einstellen" class="mt-2" v-if="this.getDiagDataPhotons(index, 'TimeEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Die Zeit definiert die Startzeit (absolut oder Zeitbezug zu Sonnenauf/Untergang). In Verbindung mit mindestens einen Wochentag wird somit ein Zeitstart durchgeführt.`"></i>
                                <i class="fi fi-rr-time-fast bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex flex-wrap justify-content-center">
                                    <div class="flex justify-content-center mb-2 mr-3">
                                        <SelectButton v-model="this.getDiagDataPhotons(index, 'TimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPhotons(index, 'TimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPhotons(index, 'TimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataPhotons(index, 'TimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">Startzeit</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col-3"></div>
                            <div class="field col-6">
                                <div class="flex justify-content-center mt-3">
                                    <Button label="Leuchtennamen ändern" @click="toogleLampNameDiag(this.getDiagDataPhotons(index, 'lampName'))" />
                                </div>
                            </div>
                            <div class="field col-3"></div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagTogglePhotons" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('photons')" />
                    </div>
                </div>
            </template>
        </Dialog>

        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <div v-show="true" class="hidden md:inline-flex social-icon">
                        <i :class="moduleData.metadata.icon + ' fs-xxlarge icon-md'"></i>
                    </div>
                    <widget-setpoint :value1="this.getModuleValue('OUT_EC')" :value2="this.getModuleValue('OUT_EC_SET')" colorVal1="fg-lightRed" colorVal2="fg-lightRed" :zeroPoint="true"></widget-setpoint>
                    <widget-setpoint :value1="this.getModuleValue('OUT_PH')" :value2="this.getModuleValue('OUT_PH_SET')" :zeroPoint="true"></widget-setpoint>
                    <div class="flex flex-column mt-3">
                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="operateState" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_EC')" :value2="this.getModuleValue('OUT_EC_SET')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_PH')" :value2="this.getModuleValue('OUT_PH_SET')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_EC_PWR')" :value2="this.getModuleValue('OUT_PH_PWR')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_Alarm_EC')" :value2="this.getModuleValue('OUT_Alarm_PH')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Rezepte" subtitle="Einstellungen Düngerezepte" icon="fi fi-rr-bottle" fgColor="yellow" bgColor="lightIndigo"
                                :badge="getModuleValue('OUT_CurrentFertilizer.fertiIndex').value + 1" @click="toggleOP($event, 'periods')" >
                            </button-enat>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Allgemein" subtitle="Einstellungen Alarme usw..." icon="fi fi-rr-settings" fgColor="yellow" bgColor="lightIndigo"
                                @click="toggleOP($event, 'misc')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center mr-3 mt-1">
                            <valuebox-enat title="Rezept" :value="getModuleValue('OUT_CurrentFertilizer.fertiName').value" :moduleData="moduleData" icon="fi fi-rr-bottle" />
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-1">
                            <valuebox-enat title="Mischung" :value="fertiABCD" :moduleData="moduleData" icon="fi fi-rr-chart-set-theory" />
                        </div>
                    </div>
                    <div class="flex mt-3 justify-content-center">
                        <widget-mini-icon :value="this.getModuleValue('OUT_FERTIADD_1')"></widget-mini-icon>
                        <widget-mini-icon :value="this.getModuleValue('OUT_FERTIADD_2')"></widget-mini-icon>
                        <widget-mini-icon :value="this.getModuleValue('OUT_FERTIADD_3')"></widget-mini-icon>
                        <widget-mini-icon :value="this.getModuleValue('OUT_FERTIADD_4')"></widget-mini-icon>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagLedName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Leuchtenbezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideLampNameDiag" @keydown.enter="saveLampNameDiag(changeDiagLedName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="lampName" ref="lampName" v-model="changeDiagLedName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="lampName" class="mr-2">Leuchten-Name</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideLampNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveLampNameDiag(changeDiagLedName)" />
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagLedId"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Leuchtenbezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideLampIdDiag" @keydown.enter="saveLampIdDiag(changeDiagLedId)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="lampId" ref="lampId" v-model="changeDiagLedId.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="lampId" class="mr-2">Leuchten-ID</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideLampIdDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveLampIdDiag(changeDiagLedId)" />
            </template>
        </Dialog>
        <Dialog
            v-model:visible="copyChannelsConfirmDiag"
            :style="{ width: '450px' }"
            header="Bestätigung"
            :modal="true"
            >
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="ioDialogData">Sollen die aktuellen Kanaleinstellungen wirklich auf alle {{ this.getModuleValue('OUT_LampCount').value }} Leuchten übertragen werden?</span>
            </div>
            <template #footer>
                <Button
                label="Nein"
                icon="pi pi-times"
                class="p-button-text"
                @click="copyChannelsConfirmDiagClose()"
                />
                <Button
                label="Ja"
                icon="pi pi-check"
                class="p-button-text"
                @click="copyLevelToAll(this.copyChannelIndex)"
                />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_Running').icon" :node="getModuleValue('OUT_Running')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniIcon from '@/components/fragments/widgetMiniIcon.vue';
import widgetSetpoint from '@/components/fragments/widgetSetpoint.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetFertiController',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniIcon,
        widgetSetpoint,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getFertiRatio: 'types/getFertiRatio',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmStatus: function () {
            // if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
            //     let iCol;
            //     const index = findIndexByDisplayName(this.nodeEntry.children, 'AlarmStatus');
            //     if (this.nodeEntry.children[index].value.length > 0) {
            //         iCol = ' alarm';
            //     } else {
            //         iCol = '';
            //     }
            //     return iCol;
            // } else {
            //     return 'mif-blocked';
            // }
            return '';
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        operateState: function () {
            if (this.getModuleValue('OUT_Running').value === true) return '🟢Ein';
            else return '🔴Aus';
        },
        fertiABCD: function () {
            return `A${this.getModuleValue('OUT_CurrentFertilizer.setpointFertiA').value} B${this.getModuleValue('OUT_CurrentFertilizer.setpointFertiB').value} C${this.getModuleValue('OUT_CurrentFertilizer.setpointFertiC').value} D${this.getModuleValue('OUT_CurrentFertilizer.setpointFertiD').value} S${this.getModuleValue('OUT_CurrentFertilizer.setpointFertiS').value}`;
        },
        iconFertiAdd1: function () {
            if (this.getModuleValue('OUT_FERTIADD_1').value) {
                return `fi-rr-syringe fg-lightGreen`;
            } else {
                return `fi-rr-syringe fg-darkGray`;
            }
        },
        iconFertiAdd2: function () {
            if (this.getModuleValue('OUT_FERTIADD_2').value) {
                return `fi-rr-syringe fg-lightGreen`;
            } else {
                return `fi-rr-syringe fg-darkGray`;
            }
        },
        iconFertiAdd3: function () {
            if (this.getModuleValue('OUT_FERTIADD_3').value) {
                return `fi-rr-syringe fg-lightGreen`;
            } else {
                return `fi-rr-syringe fg-darkGray`;
            }
        },
        iconFertiAdd4: function () {
            if (this.getModuleValue('OUT_FERTIADD_4').value) {
                return `fi-rr-syringe fg-lightGreen`;
            } else {
                return `fi-rr-syringe fg-darkGray`;
            }
        },
        iconRadiationRequest: function () {
            if (this.getModuleValue('OUT_RequestRadiation').value) {
                return `fi-rr-sun fg-lightGreen`;
            } else {
                return `fi-rr-sun fg-darkGray`;
            }
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_AktPeriodeHzg.PeriodIndex').value;
        },
        photonPreset: function () {
            let arr = [];
            for (let i=0; i<=15; i++) {
                const channel = {
                    label: `[${i+1}] ` + this.getModuleValue(`IN_PhotonDim[${i}].presetNameDim`).value,
                    value: i
                }
                arr.push(channel);
            }
            //console.log(this.getModuleValue('IN_PhotonMix[0].presetName'));
            return arr;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            nodeDataPhotons: [],
            nodeDataPhotonsOld: [],
            showDiagName: false,
            showDiagLedName: false,
            showDiagLedId: false,
            changeDiagName: null,
            changeDiagLedName: null,
            changeDiagLedId: null,
            copyChannelsConfirmDiag: false,
            copyChannelIndex: null,
            photonMixerPresets: [],
            daliChannels: [
                { label: `Kanal 1`, value: 0 }
            ],
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagPeriods: false,
            showDiagPhotons: false,
            showDiagMisc: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Rezepte',
                    icon: 'fi fi-rr-chart-set-theory',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'periods');
                    },
                },
                {
                    label: 'Allgemeine Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'misc');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataPhotons(index, displayName) {
            if (this.nodeDataPhotons.length > 0 && index >= 0) {
                const result = this.nodeDataPhotons[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataPhotonsChannel(index, arrayKey) {
            if (this.nodeDataPhotons.length > 0 && index >= 0) {
                const result = this.nodeDataPhotons[index].find(entry => entry.arrayKey === arrayKey);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.nodeDataPhotons = [];
            this.nodeDataPhotonsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'periods') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG.fertiPreset*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child1) {
                                    const child = JSON.parse(entry.child1)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataPeriods[child.index])) {
                                            this.nodeDataPeriods[child.index] = [];
                                        }
                                        this.nodeDataPeriods[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPeriodsOld = JSON.parse(JSON.stringify(this.nodeDataPeriods));
                            console.log(this.nodeDataPeriods);
                            console.log(this.nodeDataPeriodsOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagPeriods = true;
                });        
            } else if (grp === 'photons') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG.grpFixedStarts*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child1) {
                                    const child = JSON.parse(entry.child1)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataPhotons[child.index])) {
                                            this.nodeDataPhotons[child.index] = [];
                                        }
                                        this.nodeDataPhotons[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPhotonsOld = JSON.parse(JSON.stringify(this.nodeDataPhotons));
                            console.log(this.nodeDataPhotons);
                            console.log(this.nodeDataPhotonsOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagPhotons = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagTogglePeriods() {
            if (this.showDiagPeriods) {
                this.showDiagPeriods = false;
            } else {
                this.showDiagPeriods = true;
            }
            this.hideOP();
        },
        diagTogglePhotons() {
            if (this.showDiagPhotons) {
                this.showDiagPhotons = false;
            } else {
                this.showDiagPhotons = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'periods' && !this.periodCheck) {
                    // console.log(this.nodeDataPeriods);
                    // console.log(this.nodeDataPeriodsOld);
                    const flatHzg = this.nodeDataPeriods.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPeriodsOld.flatMap(innerArr => innerArr);
                    // console.log(flatHzg);
                    // console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'photons') {
                    // console.log(this.nodeDataPhotons);
                    // console.log(this.nodeDataPhotonsOld);
                    const flatHzg = this.nodeDataPhotons.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPhotonsOld.flatMap(innerArr => innerArr);
                    // console.log(flatHzg);
                    // console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    // console.log(this.nodeDataCfg);
                    // console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'periods') this.showDiagPeriods = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            if (diag === 'photons') this.showDiagPhotons = false;
                            this.hideOP();
                        }
                    });
                } else {
                    if (diag === 'periods' && !this.periodCheck) {
                        this.saveDiagNoChanges();
                        this.showDiagPeriods = false;
                    } else if (diag === 'periods' && this.periodCheck) {
                        this.saveDiagNoPeriods();
                    } else if (diag === 'misc') {
                        this.saveDiagNoChanges();
                        this.showDiagMisc = false;
                    } else if (diag === 'photons') {
                        this.saveDiagNoChanges();
                        this.showDiagPhotons = false;
                    }
                }
            }
        },
        saveDiagNoChanges() {
            setOpcValueToast(null, this.$root);
            this.dialogLoading = false;
            this.hideOP();
        },
        saveDiagNoPeriods() {
            this.$toast.add({
                severity: 'warn',
                summary: 'Eine Periode aktivieren!',
                detail: 'Es muss zumindest eine Periode aktiviert werden.',
                life: 3000,
            });
            this.dialogLoading = false;
            this.saveWait = false;
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child1) {
                const child = JSON.parse(node.child1);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        toogleLampNameDiag(name) {
            this.changeDiagLedName = JSON.parse(JSON.stringify(name));
            this.showDiagLedName = true;
        },
        hideLampNameDiag() {
            this.changeDiagLedName = null;
            this.showDiagLedName = false;
        },
        saveLampNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPhotons[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagLedName.value === 'string' && this.changeDiagLedName.value.length > 0) {
                            obj2.value = this.changeDiagLedName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideLampNameDiag();
        },
        toogleLampIdDiag(name) {
            this.changeDiagLedId = JSON.parse(JSON.stringify(name));
            this.showDiagLedId = true;
        },
        hideLampIdDiag() {
            this.changeDiagLedId = null;
            this.showDiagLedId = false;
        },
        saveLampIdDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPhotons[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagLedId.value === 'string' && this.changeDiagLedId.value.length > 0) {
                            obj2.value = this.changeDiagLedId.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideLampIdDiag();
        },
        copyChannelsConfirmDiagOpen(index) {
            this.copyChannelsConfirmDiag = true;
            this.copyChannelIndex = index;
        },
        copyChannelsConfirmDiagClose() {
            this.copyChannelsConfirmDiag = false;
            this.copyChannelIndex = null;
        },
        copyLevelToAll(lampIndex) {
            // this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value
            if (Array.isArray(this.nodeDataPhotons) && this.nodeDataPhotons.length > 0 && lampIndex >= 0) {
                // console.log(lampIndex);
                this.nodeDataPhotons.slice(0, this.getModuleValue('OUT_LampCount').value).forEach((entry, index) => {
                    // console.log(index);
                    for (let i=0; i<1; i++) {
                        // const obj1 = this.nodeDataPhotons[index].find((item) => item.arrayKey === `CFG_DaliChannels[${lampIndex}].lampDaliChannels[${i}].daliLevel`);
                        const obj1 = this.getDiagDataPhotonsChannel(lampIndex, `CFG_DaliChannels[${lampIndex}].lampDaliChannels[${i}].daliLevel`);
                        // console.log(obj1);
                        let obj2 = null;
                        obj2 = this.nodeDataPhotons[index].find((item) => item.arrayKey === `CFG_DaliChannels[${index}].lampDaliChannels[${i}].daliLevel`);
                        obj2.value = obj1.value;
                        // console.log(obj2);
                    }
                });
                // for (let i=0; i<this.nodeDataPhotons.lenght; i++) {
                //     console.log(this.nodeDataPhotons);
                //     // for (let j=0; j<7; j++) {
                //     //     // this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value
                        
                //     // }
                // }
            }
            this.copyChannelsConfirmDiagClose();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
            if (value === 'value1') this.showChart1 = true;
        },
        calcLux(val, mode) {
            return helpers.calcLight(val, mode);
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}

.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}

.card:hover .menu-overlay {
    opacity: 1;
}

.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}

// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>